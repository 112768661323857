@font-face {
  font-family: "Articulat CF";
  font-style: normal;
  src: url("./assets/fonts/Articulat-Regular.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/Articulat-Regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Articulat-Regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/Articulat-Regular.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("./assets/fonts/Articulat-Regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Articulat-Regular.svg#ArticulatCF") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Articulat CF";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/Articulat-Bold.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/Articulat-Bold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Articulat-Bold.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/Articulat-Bold.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("./assets/fonts/Articulat-Bold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Articulat-Bold.svg#ArticulatCF") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Mermaid";
  font-style: normal;
  src: url("./assets/fonts/Mermaid1001.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/Mermaid1001.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Mermaid1001.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/Mermaid1001.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("./assets/fonts/Mermaid1001.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/Mermaid1001.svg#Mermaid")
      format("svg"); /* Legacy iOS */
}

html {
  scroll-snap-type: y mandatory;
}

body {
  margin: 0;
  font-family: "Articulat CF", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-snap-type: y mandatory;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-daterange-picker__wrapper {
  border: none !important;
}

.react-calendar__tile--now {
  background-color: #87effa !important;
}
