.caroussel_root {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__arrowholder {
    width: 10%;
    &__arrow {
      display: flex;
      flex-direction: row;

      &__right {
        justify-content: flex-end;
        margin-right: 20px;
      }

      & img {
        width: 29px;
        height: 54px;

        @media only screen and (min-width: 768px) {
          width: 29px;
          height: 54px;
        }
        transition: all 0.4s;
        &:hover {
          transform: scale(1.1);
          cursor: pointer;
        }
      }

      & img:last-child {
        margin-left: 20px;
      }
    }
  }

  &__itemsholder {
    margin-top: 20px;
    width: 80%;
    overflow: hidden;
    min-height: 450px;
    @media only screen and (max-width: 768px) {
      min-height: 660px;
    }
  }

  &__items {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;

    &__item {
      position: absolute;
      top: 0;
      transition: all 0.5s ease-out;
      min-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__img {
        width: 30%;

        & img {
          border-radius: 25px;
        }
      }

      &__textbox {
        width: 70%;
        padding: 22px;
        padding-top: 10px;

        @media only screen and (min-width: 768px) {
          padding: 42px;
        }

        @media only screen and (min-width: 1200px) {
          padding: 60px;
        }

        &__title {
          display: flex;
          flex-direction: column;
          font-size: 18px;
          font-weight: bold;

          @media only screen and (min-width: 768px) {
            flex-direction: row;
          }

          @media only screen and (min-width: 1200px) {
            font-size: 30px;
          }

          &__stars {
            margin-left: 0;

            @media only screen and (min-width: 768px) {
              margin-left: 20px;
            }

            & img {
              margin-right: 4px;
            }

            &__inactive {
              opacity: 0.4;
            }
          }
        }

        &__text {
          padding-left: 20px;
          border-left: 3px solid #ffdeba;
        }
      }
    }
  }
}
